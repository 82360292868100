/* Container */
.form-container {
    padding: 2rem;
    max-width: 40rem;
    margin: 0 auto;
    background-color: white;
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Form Title */
.form-title {
    font-size: 1.875rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    color: #1f2937;
    text-align: center; /* Center the title */
}

/* Error & Success Messages */
.form-message {
    margin-bottom: 1rem;
    font-size: 1.125rem;
    text-align: center; /* Center messages */
}

.form-message-error {
    color: #dc2626;
}

.form-message-success {
    color: #16a34a;
}

/* Labels */
.form-label {
    display: block;
    font-size: 0.875rem;
    font-weight: 500;
    color: #4b5563;
    margin-bottom: 0.5rem;
}

/* Input Fields */
.form-input {
    width: 100%;
    padding: 0.625rem 0.75rem;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    background-color: #f9fafb;
    margin-bottom: 0.5rem;
    transition: border-color 0.2s;
}

.form-input:focus {
    outline: none;
    border-color: #3b82f6;
}

/* Dropdown List */
.dropdown {
    position: absolute;
    z-index: 1000;
    background-color: white;
    width: 100%;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.dropdown-item {
    padding: 0.625rem;
    cursor: pointer;
    transition: background-color 0.2s;
}

.dropdown-item:hover {
    background-color: #f3f4f6;
}

/* Button */
.form-button {
    background-color: #3b82f6;
    color: white;
    padding: 0.75rem 1.25rem;
    border-radius: 0.375rem;
    font-size: 1rem;
    font-weight: 500;
    transition: background-color 0.3s;
    width: 100%; /* Make button full width */
    margin-top: 1rem; /* Add margin on top for spacing */
}

.form-button:hover {
    background-color: #2563eb;
}

/* Loading State */
.loading {
    opacity: 0.6;
    pointer-events: none;
}

/* Time Slot Container */
.time-slot-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem; /* Space between buttons */
}

/* Time Slot Button */
.time-slot {
    background-color: #f9fafb;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: background-color 0.2s, border-color 0.2s;
    width: 75px;
}

.time-slot.selected {
    background-color: #3b82f6; /* Selected background */
    color: white; /* Selected text color */
    border-color: #2563eb; /* Selected border color */
}

/* Selected Times List */
.selected-times {
    margin-top: 1rem;
    font-size: 0.875rem;
    color: #4b5563;
    text-align: center; /* Center selected times */
}


@media screen and (max-width: 600px) {
    .form-container {
        padding: 1rem;
    }

    .form-title {
        font-size: 1.4rem;
    }

    .form-input {
        padding: 0.5rem 0.75rem;
        font-size: 14px;
    }

    .form-button {
        padding: 0.75rem;
        font-size: 14px;
    }

    .time-slot {
        padding: 0.5rem 0.35rem;
        font-size: 13px;
    }
    .form-message-error {
        font-size: 14px;
    }
  }
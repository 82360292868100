.challenge-details-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.challenge-details-container h1 {
  font-size: 2.2rem;
  margin-bottom: 20px;
  color: #222;
}

.challenge-details-container h2 {
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #444;
}

.challenge-details-container p {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 12px;
  align-self: center;
  margin-bottom: 0;
}

.challenge-details-container .player-info {
  margin-bottom: 25px;
  padding: 15px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.challenge-details-container .player-info h3 {
  font-size: 1.4rem;
  margin-bottom: 12px;
  color: #333;
}

.challenge-details-container .player-info p {
  margin-bottom: 8px;
}


.challenge-result h4 {
  font-size: 1.4rem;
  margin-bottom: 12px;
  color: #333;
}

.challenge-result p {
  margin-bottom: 8px;
}


.challenge-details {
  display: flex;
  justify-content: space-between;
}

.player-box-info {
  flex: 1;
  margin-right: 10px;
}

.player-box-info h4 {
  margin-bottom: -2px;
  font-size: 1.1rem;
  color: #333;
}

.player-box-info p {
  margin: 4px 0;
  font-size: 1.1rem;
}

.challenge-result {
  margin-top: 8px;
  padding: 15px;
  background-color: #fff;
  border-top: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 8px 20px;
}

.challenge-result p {
  margin-bottom: 0px;
  font-size: 0.9rem;
}

.challenge-result p {
  margin: 4px 0;
  font-size: 1.1rem;
}

.challenge-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.challenge-serve-info {
  margin-top: 12px;
}

.challenge-serve-info p {
  margin-bottom: 0px;
  margin-top: 10px
}

.challenge-serve-info-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.challenge-serve-info-container>img {
  margin-right: 10px;
  width: 25px;
  height: auto;
}

.challenge-serve-info-container>p {
  margin: 6px 0;
}


.challenge-info {
  margin-top: 12px;
}

.challenge-info-container {
  display: flex;
  align-items: center;
}

.challenge-info-container>img {
  margin-right: 10px;
  width: 25px;
  height: auto;
}

.challenge-info-container>p {
  margin: 6px 0;
}

.match-confirmation {
  margin-top: 20px;
  display: flex;
}

.match-confirmation img {
  margin-right: 10px;
  width: 28px;
  height: 28px;
}

.test-challenge-banner {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
}

.free-challenge-banner {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
}

.finals-challenge-banner {
  /* blue */
  background-color: #cce5ff;
  color: #004085;
  border: 1px solid #89b6e6;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
}

@media screen and (max-width: 600px) {
  .challenge-details-container h1 {
    font-size: 1.5rem;
  }

  .challenge-details-container p {
    font-size: 0.9rem;
  }

  .challenge-result h4 {
    font-size: 1.1rem;
  }

  .challenge-buttons button {
    width: 45%;
  }
}

